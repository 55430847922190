@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Nunito:wght@200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

/**************************
 **************************
 Default Css
 **************************
 **************************/
:root {
  --primary-color: #29406c;
  --green: #39b359;
  --green-light: #eaf7ee;
  --red-orange: #eb4e2b;
  --red: #ff2b43;
  --off-white: #fbedea;
  --orange: #ef9400;
  --cream: #fef7e9;
  --blue: #016de6;
  --light-blue: #e5effa;
  --gray: #69727d;
  --white: #ffffff;
  --black: #202020;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  background: var(--white);
}

.heading {
  color: var(--primary-color);
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.sub-heading {
  color: var(--primary-color);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.primary-button {
  border-radius: 5px;
  background: var(--primary-color);
  box-shadow: 0px 3px 3px 0px rgba(41, 64, 108, 0.5);
  color: var(--white);
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  height: 50px;
}

.primary-button:hover {
  background: var(--primary-color);
  box-shadow: 0px 3px 3px 0px rgba(41, 64, 108, 0.5);
  color: var(--white);
}

.status-text {
  border-radius: 5px;
  color: var(--white);
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.secondary-button {
  border-radius: 5px;
  background: var(--white);
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.25);
  color: var(--primary-color);
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  height: 50px;
}

.secondary-button:hover {
  background: var(--white);
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.25);
  color: var(--primary-color);
}

.para-medium {
  color: var(--white);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.label {
  color: #202020;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/*input,*/
/*select, {*/
/*  font-family: "Poppins", sans-serif !important;*/
/*  border-radius: 5px !important;*/
/*  background-color: var(--white) !important;*/
/*  color: rgba(32, 32, 32, 0.5) !important;*/
/*  border: none !important;*/
/*  font-size: 12px !important;*/
/*  font-style: normal !important;*/
/*  font-weight: 500 !important;*/
/*  line-height: normal !important;*/
/*  padding: 0px 16px !important;*/
/*  height: 50px !important;*/
/*}*/

input,
select,
.input-design,
textarea {
  font-family: "Poppins", sans-serif !important;
  border-radius: 5px !important;
  border: 1px solid rgba(32, 32, 32, 0.2) !important;
  background-color: var(--white) !important;
  color: rgba(32, 32, 32, 0.5) !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  padding: 0px 16px !important;
  height: 50px !important;
}

textarea {
  height: auto !important;
  padding: 20px !important;
}

input:focus,
input:focus-visible,
select:focus,
select:focus-visible,
textarea:focus,
textarea:focus-visible {
  outline: none !important;
  box-shadow: none !important;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.common-bg-layout {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.common-bg-layout::before {
  position: absolute;
  content: "";
  border-radius: 1065px;
  opacity: 0.15;
  background: var(--green);
  filter: blur(150px);
  left: 0;
  width: 600px;
  height: 600px;
  top: 0;
  z-index: -1;
}

.common-bg-layout::after {
  position: absolute;
  content: "";
  border-radius: 1065px;
  opacity: 0.15;
  background: var(--orange);
  filter: blur(150px);
  right: 0;
  width: 600px;
  height: 600px;
  bottom: 0;
  z-index: -1;
}

.mt-6 {
  margin-top: 8rem !important;
}

.my-6 {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}

.content-container {
  min-height: 100vh;
  background: #f5f5f5;
}

.cursor-pointer {
  cursor: pointer;
}

.invalid-feedback {
  display: block;
  font-weight: 700;
}

.nunito-font {
  font-family: "Nunito", sans-serif;
}

.bg-blacks {
  background-color: var(--black);
}

.hover-effect:hover {
  background-color: var(--black);
}

.react-datepicker__month-select,
.react-datepicker__year-select {
  border-radius: inherit !important;
  padding: inherit !important;
  height: 30px !important;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  margin: 0 6px !important;
}

.dropdown-toggle::after {
  display: none;
}

.common-tab {
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.2);
  background: #fff;
}

.tab-head .sub-heading {
  color: #a6a6a6;
  white-space: nowrap;
}

.tab-active {
  border-radius: 10px 10px 0px 0px;
  background: var(--primary-color);
  height: 4px;
}

.active-tab-text-color {
  color: var(--primary-color) !important;
}

.section {
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.2);
  background: #fff;
}

.normal-font-weight {
  font-weight: 500 !important;
}

.textBlack {
  color: var(--black) !important;
}

a {
  text-decoration: none;
}

input[type="number"]::-webkit-inner-spin-button,
.number-input input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/**************************
 **************************
 Toasts Css
 **************************
 **************************/

.toast.fade {
  position: absolute;
  right: 40px;
  z-index: 999;
  top: 5%;
  width: auto;
  border-radius: 5px;
}

.toast-success.fade {
  border: 1px solid #bee5c8;
  background: #eaf7ee;
  box-shadow: 3px 3px 4px 0px rgba(57, 179, 89, 0.1);
}

.toast-error.fade {
  border: 1px solid #f4c5bb;
  background: #fbedea;
  box-shadow: 3px 3px 4px 0px rgba(57, 179, 89, 0.1);
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container input {
  padding: 0 !important;
}

/**************************
 **************************
 Preloader Css
 **************************
 **************************/
.logo-text {
  color: #777a7a;
  font-family: "Inter", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.logo-arabic {
  font-weight: 400;
}

/**************************
 **************************
 Authentication Css
 **************************
 **************************/

.login {
  width: 100%;
}

.login .login-card {
  padding: 50px 70px;
  background-image: url("./assets/images/authentication/login-bg.jpeg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 15px;
  width: 50%;
  position: relative;
}

.login .login-card-header,
.login .login-card-body {
  z-index: 1;
  position: relative;
}

.login-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background-color: rgba(41, 64, 108, 0.83);
}

.login input,
.custom-input {
  border: none !important;
  color: rgba(0, 0, 0, 0.6) !important;
  font-size: 14px !important;
  padding: 0 8px !important;
  height: 46px !important;
}

.select-search-value input {
  border-left: none !important;
  border-right: none !important;
  color: rgba(0, 0, 0, 0.6) !important;
  font-size: 14px !important;
  padding: 0 8px !important;
}

.select-search-container {
  width: 100%;
}

.select-search-select {
  border: none;
}

.select-search-is-selected {
  color: var(--primary-color);
}

.select-search-option,
.select-search-not-found {
  color: rgba(0, 0, 0, 0.6) !important;
  font-size: 14px !important;
  padding: 0 8px !important;
}

.login .login-card-header h3 {
  color: var(--white);
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.login .login-card-header p {
  color: var(--white);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.login .primary-button {
  background: var(--black);
}

.login .form-check input {
  width: 20px;
  height: 20px !important;
}

.login .form-check-input:checked {
  background-color: var(--black) !important;
  border-color: var(--black) !important;
}

.login .form-check-label {
  color: var(--white);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 6px;
}

.login a,
.login button {
  color: var(--white);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.otp-input-container {
  display: flex;
  gap: 20px;
  margin: auto;
}

.otp-input-container input {
  padding: 0px 0px !important;
  text-align: center !important;
  background: transparent;
  border: 1px solid #868da4;
  border-radius: 10px;
  width: 48px;
  height: 48px !important;
  font-family: "Outfit", serif;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 22px !important;
  line-height: 28px !important;
  color: #252a3a !important;
}

.login .btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  color: var(--white) !important;
  border: none !important;
}

/**************************
 **************************
 Profile Completion Css
 **************************
 **************************/
.profile-completion {
  width: 100%;
}

.profile-completion .profile-completion-card {
  padding: 50px 70px;
  background: #29406c;
  border-radius: 15px;
  width: 66%;
  position: relative;
}

.profile-completion input {
  border-left: none !important;
  border-right: none !important;
  color: rgba(0, 0, 0, 0.6) !important;
  font-size: 14px !important;
  padding: 0 8px !important;
}

.profile-completion .profile-completion-card-header h3 {
  color: var(--white);
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.profile-completion .profile-completion-card-header p {
  color: var(--white);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.country-code {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
}

.profile-completion .primary-button {
  background: var(--black);
}

.profile-label {
  position: relative;
  display: flex !important;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 20px;
  background-color: var(--white);
  border-radius: 5px;
  border: 1px solid rgba(41, 64, 108, 0.83);
  box-shadow: 0px 4px 6px -1px rgba(11, 123, 195, 0.18);
  height: 198px;
}

.profile-completion .form-check input {
  width: 20px;
  height: 20px !important;
}

.profile-completion .form-check-input:checked {
  background-color: var(--black) !important;
  border-color: var(--black) !important;
}

.profile-completion .form-check-label {
  color: var(--white);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 6px;
}

.profile-completion .countries-list li:last-child hr {
  display: none;
}
/**************************
 **************************
 Modals Css
 **************************
 **************************/

.general-modal .modal-content {
  border-radius: 10px;
}

.general-modal .modal-body {
  padding: 30px 24px;
}

/* reset-password-success-modal */
.reset-password-success-modal h4 {
  color: #29406c;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.reset-password-success-modal p {
  color: #7d7d7d;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.reset-password-success-modal .primary-button {
  width: 40%;
}

/**************************
 **************************
 Sidebar Css
 **************************
 **************************/
.my-sidebar {
  height: auto !important;
  min-height: 100vh !important;
  background: var(--primary-color) !important;
  box-shadow: 15px 4px 25px rgba(0, 83, 165, 0.08);
}

.head-div {
  padding: 24px 28px 0px 28px !important;
  justify-content: center !important;
}

.pro-sidebar-content {
  padding-top: 0 !important;
}

.head-div .head-text {
  margin-top: 4px !important;
}

.head-div span:last-child {
  height: fit-content !important;
}

.hPqIab,
.jiIAEz,
.sc-hknOHE {
  border: none !important;
}

.item-content {
  color: var(--white);
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
}

.item-content button {
  color: var(--white);
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
}

.kjmbrI {
  padding: 0 !important;
}

.sidebar-item {
  color: var(--white);
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0px 0px !important;
  display: flex !important;
  align-items: center !important;
}

.sidebar-item li {
  margin: 0 !important;
}

.sidebar-item li div {
  padding: 8px 0px 8px 0px !important;
}

.hsgNmC,
.hAsvpW {
  margin: 0 !important;
}

.sidebar-item-toggled {
  padding: 12px 20px !important;
  margin: 12px 26px !important;
  display: flex !important;
  align-items: center !important;
}

.head-div.toggled .head-text {
  display: block !important;
}

/*.pro-sidebar-content .iQJykF {*/
/*    padding-top: 22px !important;*/
/*}*/

.pro-sidebar-footer a {
  padding: 0 0px !important;
}

.pro-sidebar-footer button:first-child {
  padding: 10px 0px !important;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--white);
}

.pro-sidebar-footer button:last-child {
  padding: 10px 20px !important;
  margin: 0 !important;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--red) !important;
}

.hPqIab .head-div .icon-suffix {
  height: 0;
}

.hsgNmC.toggled {
  display: none !important;
}

.head-div.toggled .head-text {
  display: block !important;
}

.kjmbrI {
  padding: 0 !important;
}

.jiIAEz .head-div .icon-suffix {
  height: 0 !important;
}

.sc-IeChK {
  padding: 0 !important;
}

.sidebar-content {
  flex-grow: inherit !important;
  -webkit-flex-grow: inherit !important;
  min-height: 66vh;
  /*margin-top: auto;*/
  /*margin-bottom: auto;*/
}

.sidebar-item-active {
  background: white !important;
  border-radius: 10px !important;
  padding: 10px 0px !important;
}

.sidebar-item-active {
  color: var(--primary-color) !important;
}

.icon-yuyin,
.icon-shipin {
  display: none !important;
}

.sidebar-content .accordion-item {
  background: transparent !important;
  border: none !important;
  outline: none;
}

.sidebar-content .accordion-item:first-of-type .accordion-button:focus {
  box-shadow: none !important;
}

.sidebar-content .accordion-item:last-of-type .accordion-button,
.sidebar-content
  .sub-dropdown
  .accordion-item:last-of-type
  .accordion-button.collapsed {
  background: var(--white) !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: var(--primary-color);
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  box-shadow: none;
  padding-bottom: 8px;
}

.sidebar-content .sub-dropdown .accordion-item:last-of-type .accordion-button,
.sidebar-content
  .sub-dropdown
  .accordion-item:last-of-type
  .accordion-button.collapsed {
  padding: 0;
  font-size: 14px;
}

.sidebar-content .accordion-item:last-of-type .accordion-button.collapsed {
  color: var(--white);
  background: transparent !important;
  border-radius: 0;
  padding: 0;
}

.sidebar-content .accordion-collapse {
  transition: none;
}

.sidebar-content .accordion-collapse.show .accordion-body {
  background: var(--white) !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.sidebar-content .sub-dropdown .accordion-collapse.show .accordion-body {
  padding-bottom: 0 !important;
}

.sidebar-content .accordion-collapse.show .accordion-body a {
  color: var(--primary-color) !important;
}

.sidebar-content .accordion-button::after {
  background-image: url("./assets/images/sidebar/arrow-down.svg");
}

.sidebar-content .sub-dropdown .accordion-button::after {
  background-image: url("./assets/images/sidebar/arrow-down-blue.svg");
  background-size: 16px 16px;
}

.sidebar-content .sub-dropdown .accordion-button:not(.collapsed)::after {
  background-size: 16px 16px;
}

.sidebar-content .accordion-button:not(.collapsed)::after {
  background-image: url("./assets/images/sidebar/arrow-up-blue.svg");
  transform: none;
}

.sidebar-logo-text {
  color: var(--white);
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/**************************
 **************************
 Settings Css
 **************************
 **************************/
.user-settings {
}

.settings-profile-img {
  width: fit-content;
  height: 356px;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.edit-icon {
  background-color: var(--primary-color);
  border-radius: 50px;
  width: 32px;
  height: 32px;
}

th {
  color: rgba(32, 32, 32, 0.7) !important;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  white-space: nowrap;
}

td {
  color: #29406c !important;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  box-shadow: none !important;
  border-color: rgba(32, 32, 32, 0.2) !important;
  padding: 0.7rem 0.5rem !important;
  white-space: nowrap;
}

.settings .add-user .custom-input,
.border-fill {
  border: 1px solid rgba(32, 32, 32, 0.2) !important;
}

.settings .form-check input {
  width: 24px;
  height: 24px !important;
  padding: 0 !important;
}

.settings .form-check-input:checked {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.settings .form-check-label {
  color: #919191;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 6px;
}

/**************************
 **************************
 Employee Css
 **************************
 **************************/
.dropdown-toggle-white {
  border-radius: 5px;
  border: 0.5px solid rgba(32, 32, 32, 0.2);
  background: var(--white);
  box-shadow: 1px 3px 3px 0px rgba(0, 0, 0, 0.1);
  color: var(--primary-color);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 8px 20px;
}

.dropdown-toggle-white:hover,
.dropdown-toggle-white:focus-visible,
.dropdown-toggle-white:active {
  background-color: inherit !important;
  color: inherit !important;
  border: 0.5px solid rgba(32, 32, 32, 0.2) !important;
  box-shadow: 1px 3px 3px 0px rgba(0, 0, 0, 0.1) !important;
}

.dropdown-toggle-blue {
  background: var(--primary-color);
  color: var(--white);
}

.dropdown-toggle-blue:hover,
.dropdown-toggle-blue:focus-visible,
.dropdown-toggle-blue:active {
  background-color: var(--primary-color) !important;
  color: var(--white) !important;
  border: 0.5px solid rgba(32, 32, 32, 0.2) !important;
  box-shadow: 1px 3px 3px 0px rgba(0, 0, 0, 0.1) !important;
}

.rotate-left {
  transform: rotate(90deg);
}

.rotate-right {
  transform: rotate(-90deg);
}

.pagination-custom .page-link {
  color: #29406c;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.pagination-custom .page-item.active-page .page-link {
  background: var(--primary-color);
  color: var(--white);
}

.pagination-custom .page-link:focus,
.pagination-custom .page-link:focus-visible,
.pagination-custom .page-link:active {
  box-shadow: none;
  background: transparent;
  outline: none;
}

.add-profile-image {
  background-color: var(--primary-color);
  border-radius: 50px;
  width: 52px;
  height: 52px;
}

.upload-para {
  color: rgba(32, 32, 32, 0.5);
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.profile-image-label {
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.2);
  background: #fff;
  padding: 0px 24px;
  height: 250px;
  width: fit-content;
}

.employees .form-check input {
  width: 24px;
  height: 24px !important;
  padding: 0 !important;
}

.employees .form-check-input:checked {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.employees .form-check-label {
  color: var(--primary-color);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 6px;
}

/**************************
 **************************
 Attendance Css
 **************************
 **************************/

.attendance .settings .form-check-label {
  color: var(--primary-color) !important;
}

.attendance td {
  vertical-align: middle;
}

.modules-short-detail-item:hover {
  background-color: var(--primary-color);
}

.modules-short-detail-item:hover h4 {
  color: var(--white) !important;
}

.modules-short-detail-item:hover p {
  color: #ffffff80 !important;
}

.apex-chart-side-heading {
  color: rgba(32, 32, 32, 0.7) !important;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/**************************
 **************************
 Notifications Css
 **************************
 **************************/
.notifications .notification-card {
  background: #ffffff;
  border-radius: 15px;
  padding: 40px 30px;
  position: relative;
}

.notifications .notification-card * {
  position: relative;
}

.notifications .notification-card ul li .notification-summary-list-item {
  padding: 12px 16px;
}

.notifications .notification-card ul li .notification-summary-list-item span {
  font-weight: 500;
}

.notifications .notification-card ul li .notification-summary-list-item p {
  color: #5e657b;
  width: fit-content;
}

.notifications .notification-card ul li .unread-notification {
  background: #29406c1a;
}

.notifications .notification-card ul li hr {
  border: 1px solid #d3daed;
  margin: 0;
  opacity: 0.7;
}

.notifications .notification-card ul li:last-child hr {
  display: none;
}

/**************************
 **************************
 Letter format A Css
 **************************
 **************************/
.letter-bg {
  background: #f5f5f5;
  padding: 100px 0;
}

.letter-format-a {
  position: relative;
  overflow: hidden;
  background: white;
  padding: 96px 60px 50px;
  height: 100vh;
}

.letter-right-bg {
  background-image: url("./assets/images/letters/letter-right-bg.png");
  position: absolute;
  width: 1000px;
  height: 1300px;
  content: "";
  top: -224px;
  right: -770px;
  transform: rotate(-62deg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top right;
}

.letter-left-bg {
  background-image: url("./assets/images/letters/letter-left-bg.png");
  position: absolute;
  width: 1000px;
  height: 1300px;
  content: "";
  bottom: -458px;
  left: -34px;
  transform: rotate(-66deg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom left;
}

.letter-content {
  background: url("./assets/images/letters/logo-bg.png") center center / 80%
    no-repeat;
  min-height: 56vh;
}

.letter-content-b {
  min-height: 76vh;
}

.letter-format-b {
  padding: 20px 30px;
}

/**************************
 **************************
 Petty Cash Css
 **************************
 **************************/

.uploaded-item {
  border-radius: 10px;
  overflow: hidden;
  height: 222px;
  background: #ececec;
  display: flex;
  align-items: center;
  justify-content: center;
}

.uploaded-item .upload-item-body {
  background: rgba(43, 38, 38, 0.6);
  border-radius: 5px 5px 0px 0px;
  padding: 2px 6px;
  position: absolute;
  width: 100%;
  bottom: 0;
  text-align: center;
  z-index: 1;
  left: 0;
}

.uploaded-item .upload-item-body p {
  font-family: "Outfit", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #ffffff;
}

/**************************
 **************************
 Overwrite Css
 **************************
 **************************/
.disabled-input {
  background-color: #999999 !important;
}

.form-control:disabled {
  background-color: #999999 !important;
  color: var(--white) !important;
}

.custom-height {
  height: 34px !important;
}

textarea {
  font-size: 14px !important;
}

.custom-toast .Toastify__close-button {
  color: white;
}

.table-container-letter {
  display: flex;
  border: 1px solid #a8a8a8; /* Add border for styling */
}

.table-content-letter {
  flex-grow: 1;
  overflow-x: auto; /* Add horizontal scroll for the content */
}

/* Offer Letter Emp Detail */

.table-container-letter .emp-detail-table td {
  border-right: 1px solid #a8a8a8; /* Add right border for styling */
}

.table-container-letter .emp-detail-table td:last-child {
  border-right: none; /* Remove right border for the last cell in each row */
  text-align: start;
  margin-left: 10px;
}

.table-container-letter .emp-detail-table tbody tr {
  display: grid;
  grid-template-columns: 1fr 2fr; /* Adjust the column ratio based on your preference */
  border-bottom: 1px solid #a8a8a8;
}

.table-container-letter .emp-detail-table tbody tr:last-child {
  border-bottom: none;
}

.table-container-letter .emp-detail-table td:first-child {
  border-right: 1px solid #a8a8a8; /* Add border for the first cell in each row */
  text-align: start;
}

.table-container-letter .emp-detail-table tbody tr td {
  background: transparent;
  font-size: 9px;
  font-weight: 500;
  color: #000000 !important;
  padding: 2px 10px 0 !important;
}

.text-uppercase {
  text-transform: uppercase;
}

.emp-detail-table ul {
  text-align: justify;
}

.emp-detail-table ul li {
  font-size: 10px;
  font-weight: 500;
  color: #000000 !important;
}

.invoice-container-letter .emp-detail-table td {
  white-space: normal;
  font-size: 7.5px !important;
  font-weight: 500 !important;
  display: flex;
  align-items: center;
}

.payslip .emp-detail-table td {
  white-space: normal;
  font-size: 9px !important;
  font-weight: 500 !important;
  display: flex;
  align-items: center;
}
/* offer-letter-offer-table */

.qr-code-letter svg {
  width: 90px;
  height: 90px;
}

.timesheet-input {
  width: 46px;
  padding: 0 !important;
  height: 46px !important;
  text-align: center;
}

.print-sheet td,
.print-sheet th {
  font-size: 8px;
  font-weight: 500;
  padding: 10px 2px !important;
}

.print-table td,
.print-table th {
  font-size: 10px;
  font-weight: 500;
  padding: 10px 2px !important;
}

.react-datepicker__month-text {
  font-size: 16px !important;
}

.account-sub-type-dropdown .dropdown-item {
  font-size: 14px !important;
}

.hide-letter-head{
  display: flex;
  align-items: center;
  gap: 10px;
}

.hide-letter-head input {
  width: 32px !important;
  padding: 0 !important;
  height: 32px !important;
}

.hide-letter-head .form-check-input:checked {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.table-checkbox{
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

.table-checkbox input {
  width: 24px !important;
  padding: 0 !important;
  height: 24px !important;
}

.table-checkbox .form-check-input:checked {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.table-checkbox label{
  color: rgba(32, 32, 32, 0.7) !important;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  white-space: nowrap;
}

.react-datepicker-popper{
    z-index: 9999 !important;
}


/**************************
 **************************
 Scrollbar Css
 **************************
 **************************/

/* For WebKit-based browsers (Chrome, Safari, etc.) */
/* You can adjust the properties as needed */
::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: transparent; /* Color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: var(--primary-color); /* Color of the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color); /* Color of the scrollbar thumb on hover */
}

/* For Firefox and other browsers that support scrollbar-width and scrollbar-color properties */
/* You can adjust the properties as needed */
/* Note: These properties may need vendor prefixes for older versions of Firefox */
/* See https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-width */
/* See https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-color */
/* The following example sets the same style as above */
/* Replace the colors and other properties as needed */
/* Note: The properties might not be supported in all browsers */
/* You might need to test and add vendor prefixes for compatibility */

/* Hide scrollbar */
/* This is to prevent default scrollbar appearance in Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--primary-color) transparent;
}

/* Color of the scrollbar thumb */
*::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
}

/* Color of the scrollbar track */
*::-webkit-scrollbar-track {
  background: transparent;
}

/* Color of the scrollbar thumb on hover */
*::-webkit-scrollbar-thumb:hover {
  background-color: var(--primary-color);
}

.list-group-item .sub-heading{
  color: black;
}

.list-group-item.active{
  border-color: var(--primary-color) !important;
    background-color: var(--primary-color) !important;
    color: var(--white) !important;
}

.list-group-item.active .sub-heading{
  color: var(--white)
}
